import {theme} from '@styles/theme';
import styled from 'styled-components';
import {StyledTextareaProps} from './Textarea';

export const TextareaWrapper = styled.div`
  display: flex;
  border: ${({borderColor, focus, theme, error}: StyledTextareaProps) => {
    switch (true) {
      case borderColor && borderColor.length !== 0 && error && error!.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      case borderColor && borderColor.length !== 0:
        return `1px solid ${borderColor}`;
      case focus:
        return `1px solid ${theme.colors.brandGreenV2}`;
      case error && error.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      default:
        return `1px solid ${theme.colors.lightGrayV2}`;
    }
  }};
  border-radius: 4px;
  background-color: transparent;
  width: 100%;
  position: relative;
  transition: all 0.5s;
  & label {
    white-space: nowrap;
    background-color: ${({labelBGColor}: StyledTextareaProps) =>
      labelBGColor ? `${labelBGColor}` : 'white'};
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: ${({labelColor, theme, error}: StyledTextareaProps) => {
      switch (true) {
        case labelColor && labelColor.length !== 0 && error && error!.length !== 0:
          return `${theme.colors.darkRed}`;
        case labelColor && labelColor.length !== 0:
          return `${labelColor}`;
        case error && error.length !== 0:
          return `${theme.colors.darkRed}`;
        default:
          return `${theme.colors.gray}`;
      }
    }};
    padding: 0 4px;
    position: absolute;
    top: -7px;
    left: 7px;
  }
  & textarea {
    border: none;
    outline: none;
    padding: ${({withoutPadding}: StyledTextareaProps) =>
      withoutPadding ? '' : '16px 10px 16px 12px'};
    margin: 0;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 18px;
    background-color: transparent;
    width: 100%;
    letter-spacing: 0.5px;
    resize: ${({resize}) => (resize ? '' : 'none')};
    color: ${({textColor}: StyledTextareaProps) =>
      textColor ? `${textColor}` : 'black'};
    width: 100%;
    ::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: ${({placeholderColor}) =>
        placeholderColor ? `${placeholderColor}` : `${theme.colors.gray}`};
    }
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({textColor}: StyledTextareaProps) =>
      textColor ? `${textColor}` : 'black'} !important;
  }
`;

export const ErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkRed};
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 16px;
  width: fit-content;
  background-color: #fff;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  z-index: 1;
`;
