import {E164Number} from 'libphonenumber-js/types';
import {FC, useState} from 'react';
import {PatternFormat} from 'react-number-format';
import {DefaultTheme} from 'styled-components';
import {ErrorText, InputWrapper} from './PhoneInput.styles';

interface InputProps {
  id: string;
  name?: string;
  min?: number;
  defaultValue?: string | number;
  max?: number;
  placeholder?: string;
  value?: E164Number;
  onChange: (value?: string) => void;
  onBlur?: () => void;
  label?: string;
  style?: Record<string, string>;
  labelBGColor?: string;
  borderColor?: string;
  labelColor?: string;
  textColor?: string;
  testID?: string;
  error?: string;
  className?: string;
  placeholderColor?: string;
  allowEmptyFormatting?: boolean;
  disabled?: boolean;
}

export interface StyledInputProps {
  labelBGColor: string;
  labelColor: string;
  borderColor: string;
  textColor: string;
  focus: boolean;
  theme: DefaultTheme;
  error: string | undefined;
  placeholderColor: string;
}

const PhoneInput: FC<InputProps> = ({
  value,
  defaultValue,
  onChange,
  onBlur,
  min,
  max,
  placeholder,
  id,
  name,
  label,
  style,
  labelBGColor,
  borderColor,
  labelColor,
  textColor,
  testID,
  error,
  className,
  placeholderColor,
  allowEmptyFormatting = true,
  disabled
}) => {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <InputWrapper
      className={className}
      onFocus={() => setIsFocus(true)}
      focus={isFocus}
      error={error}
      style={style}
      labelBGColor={labelBGColor!}
      borderColor={borderColor!}
      labelColor={labelColor!}
      textColor={textColor!}
      placeholderColor={placeholderColor!}>
      <label htmlFor={id}>{label}</label>
      <PatternFormat
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={e => onChange(e.target.value)}
        data-testid={testID}
        min={min}
        max={max}
        placeholder={placeholder}
        onBlur={() => {
          onBlur && onBlur();
          setIsFocus(false)
        }}
        format="+7 (###) ###-##-##"
        allowEmptyFormatting={allowEmptyFormatting}
        mask="_"
        disabled={disabled}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </InputWrapper>
  );
};

export default PhoneInput;
