import {theme} from '@styles/theme';
import {limitStr} from '@utils/formatters';
import styled from 'styled-components';
import {StyledInputProps} from './FileInput';

export const FileInputWrapper = styled.div`
  display: flex;
  border: ${({borderColor, focus, theme, error}: StyledInputProps) => {
    switch (true) {
      case borderColor && borderColor.length !== 0 && error && error!.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      case borderColor && borderColor.length !== 0:
        return `1px solid ${borderColor}`;
      case focus:
        return `1px solid ${theme.colors.gray}`;
      case error && error.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      default:
        return `1px solid ${theme.colors.lightGrayV2}`;
    }
  }};
  transition: all 0.5s;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;
  position: relative;
  cursor: pointer;
  & label {
    white-space: nowrap;
    background-color: ${({labelBGColor}: StyledInputProps) =>
      labelBGColor ? `${labelBGColor}` : 'white'};
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: ${({labelColor, theme, error}: StyledInputProps) => {
      switch (true) {
        case labelColor && labelColor.length !== 0 && error && error!.length !== 0:
          return `${theme.colors.darkRed}`;
        case labelColor && labelColor.length !== 0:
          return `${labelColor}`;
        case error && error.length !== 0:
          return `${theme.colors.darkRed}`;
        default:
          return `${theme.colors.gray}`;
      }
    }};
    padding: 0 2px;
    position: absolute;
    top: -7px;
    left: 10px;
  }
  & input[type='file'] {
    opacity: 0;
    width: 100%;
    border: none;
    outline: none;
    padding: 13px 10px 14px 12px;
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    background-color: transparent;
    letter-spacing: 0.5px;
    color: ${({textColor}: StyledInputProps) => (textColor ? `${textColor}` : 'black')};
    cursor: pointer;
    z-index: 999;
    ::file-selector-button {
      cursor: pointer;
    }
  }
  &:before {
    ${({fileName}) =>
      fileName ? `content: '${limitStr(fileName, 22)}';` : `content: 'Загрузите файл';`}
    position: absolute;
    font-size: 18px;
    font-weight: ${({fileName}: StyledInputProps) => (fileName ? '500' : '400')};
    top: 30%;
    left: 11px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${({placeholderColor, fileName, textColor}: StyledInputProps) =>
      fileName
        ? `${textColor}`
        : `${placeholderColor}`
        ? `${placeholderColor}`
        : `${theme.colors.gray}`};
  }
  &:after {
    content: url('/assets/icons/fileInputIcon.svg');
    position: absolute;
    right: 15px;
    top: 35%;
    z-index: -1;
    ${({lightIcon}: StyledInputProps) => (lightIcon ? 'filter: invert(100)' : '')}
  }
`;
