import {useWindowDimensions} from '@hooks';
import {ErrMessage} from '@styles/pages/_app.styles';
import Image from 'next/legacy/image';
import {ChangeEvent, FC, useEffect, useState} from 'react';
import {
  CaptchaContainer,
  CaptchaImageWrapper,
  CaptchaInputWrapper,
  CaptchaWrapper,
  ReloadCaptchaButton,
  StyledInput,
} from './Captcha.styles';

interface CaptchaProps {
  type: 'ral' | 'recaptcha';
  onChange?: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  value?: string;
  error?: string;
  validationError?: boolean;
  vertical?: boolean;
  className?: string;
}

const Captcha: FC<CaptchaProps> = ({
  type,
  onChange,
  value,
  error,
  validationError,
  vertical,
  className,
}) => {
  const [captchaToken, setCaptchaToken] = useState(new Date().getTime());
  const [placeholderText, setPlaceholderText] = useState('');

  const {width} = useWindowDimensions();

  useEffect(() => {
    if (width && width > 1000) {
      setPlaceholderText('Код с картинки');
    } else {
      setPlaceholderText('Код');
    }
  }, [width]);

  return (
    <CaptchaContainer type={type}>
      {type === 'ral' && onChange ? (
        <CaptchaWrapper vertical={vertical} className={className}>
          <CaptchaImageWrapper>
            <Image
              src={`https://www.rosagroleasing.ru/formcaptcha/?name=callbackModal&${captchaToken}`}
              layout="intrinsic"
              width={160}
              height={27}
              object-fit="contain"
              alt="Иконка"
            />
          </CaptchaImageWrapper>
          <ReloadCaptchaButton
            onClick={e => {
              setCaptchaToken(new Date().getTime());
            }}>
            <Image
              src={'/assets/icons/reloadIcon.svg'}
              layout="intrinsic"
              width={22}
              height={22}
              object-fit="contain"
              alt="Иконка обновления"
            />
          </ReloadCaptchaButton>
          <CaptchaInputWrapper>
            <StyledInput
              id="captcha"
              type="number"
              onChange={onChange}
              value={value}
              placeholder={placeholderText}
              style={{backgroundColor: 'white'}}
              error={error}
              validationError={validationError}
            />
            {error && <ErrMessage>{error}</ErrMessage>}
          </CaptchaInputWrapper>
        </CaptchaWrapper>
      ) : null}
    </CaptchaContainer>
  );
};

export default Captcha;
