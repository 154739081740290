import {theme} from '@styles/theme';
import styled from 'styled-components';
import {StyledCheckboxProps} from './Checkbox';

export const CheckboxWrapper = styled.label<StyledCheckboxProps>`
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: fit-content;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'all')};
  & > * {
    cursor: pointer;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  align-items: ${({alignCenter}) => (alignCenter ? 'center' : 'flex-start')};
`;

export const CheckboxFilledSquare = styled.div`
  display: inline-block;
  height: ${({checkboxSquareHeight}: StyledCheckboxProps) =>
    checkboxSquareHeight ? `${checkboxSquareHeight}px` : '28px'};
  width: ${({checkboxSquareWidth}: StyledCheckboxProps) =>
    checkboxSquareWidth ? `${checkboxSquareWidth}px` : '28px'};
  min-width: ${({checkboxSquareWidth}: StyledCheckboxProps) =>
    checkboxSquareWidth ? `${checkboxSquareWidth}px` : '28px'};
  background: ${({active}: StyledCheckboxProps) =>
    active ? `${theme.colors.brandGreenV2}` : 'transparent'};
  border: ${({active, error, squareColor}: StyledCheckboxProps) => {
    switch (true) {
      case squareColor && squareColor.length !== 0 && error && error!.length !== 0:
        return `1.5px solid ${theme.colors.darkRed}`;
      case squareColor && squareColor.length !== 0 && !active:
        return `1.5px solid ${squareColor}`;
      case active:
        return `1.5px solid ${theme.colors.brandGreenV2}`;
      case error && error.length !== 0:
        return `1.5px solid ${theme.colors.darkRed}`;
      default:
        return `1.5px solid ${theme.colors.gray}`;
    }
  }};
  border-radius: ${theme.rounded};
  margin-right: ${({marginBetweenLabel}: StyledCheckboxProps) =>
    marginBetweenLabel ? `${marginBetweenLabel}px` : '10px'};
  position: relative;
  :before {
    ${({active}: StyledCheckboxProps) =>
      active ? `content: url(/assets/icons/checkboxWhiteIcon.svg)` : `content: ''`};
    position: absolute;
    top: ${({checkMarkPosTop}: StyledCheckboxProps) =>
      checkMarkPosTop ? `${checkMarkPosTop}%` : '25%'};
    right: ${({checkMarkPosRight}: StyledCheckboxProps) =>
      checkMarkPosRight ? `${checkMarkPosRight}%` : '25%'};
  }
`;

export const CheckboxFilledLabel = styled.div`
  color: ${({labelColor}: StyledCheckboxProps) =>
    labelColor ? `${labelColor}` : `${theme.colors.gray}`};
  font-size: ${({labelFontSize}: StyledCheckboxProps) =>
    labelFontSize ? `${labelFontSize}px` : '14px'};
  width: ${({labelWidth}: StyledCheckboxProps) =>
    labelWidth ? `${labelWidth}%` : '100%'};
  line-height: 16px;
  letter-spacing: 0.1px;
`;

export const CheckboxOutlinedSquare = styled.div`
  display: inline-block;
  height: ${({checkboxSquareHeight}: StyledCheckboxProps) =>
    checkboxSquareHeight ? `${checkboxSquareHeight}px` : '28px'};
  width: ${({checkboxSquareWidth}: StyledCheckboxProps) =>
    checkboxSquareWidth ? `${checkboxSquareWidth}px` : '28px'};
  min-width: ${({checkboxSquareWidth}: StyledCheckboxProps) =>
    checkboxSquareWidth ? `${checkboxSquareWidth}px` : '28px'};
  background: transparent;
  border: ${({active, error, squareColor}: StyledCheckboxProps) => {
    switch (true) {
      case squareColor && squareColor.length !== 0 && error && error!.length !== 0:
        return `1.5px solid ${theme.colors.darkRed}`;
      case squareColor && squareColor.length !== 0 && !active:
        return `1.5px solid ${squareColor}`;
      case active:
        return `1.5px solid ${theme.colors.brandGreenV2}`;
      case error && error.length !== 0:
        return `1.5px solid ${theme.colors.darkRed}`;
      default:
        return `1.5px solid ${theme.colors.gray}`;
    }
  }};
  border-radius: ${theme.rounded};
  margin-right: ${({marginBetweenLabel}: StyledCheckboxProps) =>
    marginBetweenLabel ? `${marginBetweenLabel}px` : '15px'};
  position: relative;
  :before {
    ${({active}: StyledCheckboxProps) =>
      active ? `content: url(/assets/icons/checkboxGreenIcon.svg)` : `content: ''`};
    position: absolute;
    top: ${({checkMarkPosTop}: StyledCheckboxProps) =>
      checkMarkPosTop ? `${checkMarkPosTop}%` : '10px'};
    right: ${({checkMarkPosRight}: StyledCheckboxProps) =>
      checkMarkPosRight ? `${checkMarkPosRight}%` : '10px'};
    width: 100%;
    height: 100%;
  }
`;

export const CheckboxOutlinedLabel = styled.div`
  color: ${({labelColor}: StyledCheckboxProps) =>
    labelColor ? `${labelColor}` : 'black'};
  font-size: ${({labelFontSize}: StyledCheckboxProps) =>
    labelFontSize ? `${labelFontSize}px` : '15px'};
  width: ${({labelWidth}: StyledCheckboxProps) =>
    labelWidth ? `${labelWidth}%` : '100%'};
  line-height: 20px;
  letter-spacing: 0.5px;
`;
