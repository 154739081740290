import React, {FC, useEffect, useState} from 'react';
import HtmlParser from 'react-html-parser';
import {
  CheckboxWrapper,
  CheckboxFilledSquare,
  CheckboxFilledLabel,
  CheckboxOutlinedSquare,
  CheckboxOutlinedLabel,
} from './Checkbox.styles';

export interface CheckboxProps {
  id: string;
  name?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: any) => void;
  testID?: string;
  label?: string;
  checkboxSquareWidth?: number;
  checkboxSquareHeight?: number;
  checkMarkPosTop?: number;
  checkMarkPosRight?: number;
  marginBetweenLabel?: number;
  variant?: 'filled' | 'outlined';
  labelColor?: string;
  labelFontSize?: string;
  error?: string;
  labelWidth?: number;
  alignCenter?: boolean;
  squareColor?: string;
  className?: string;
  disabled?: boolean;
}

export interface StyledCheckboxProps {
  active?: boolean;
  checkboxSquareWidth?: number | undefined;
  checkboxSquareHeight?: number | undefined;
  checkMarkPosTop?: number | undefined;
  checkMarkPosRight?: number | undefined;
  marginBetweenLabel?: number | undefined;
  labelColor?: string | undefined;
  labelFontSize?: string | undefined;
  error?: string | undefined;
  labelWidth?: number | undefined;
  alignCenter?: boolean;
  squareColor?: string;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  name,
  defaultChecked,
  checked,
  onChange,
  label,
  checkboxSquareHeight,
  checkboxSquareWidth,
  checkMarkPosRight,
  checkMarkPosTop,
  marginBetweenLabel,
  labelColor,
  labelFontSize,
  variant = 'filled',
  error,
  labelWidth,
  alignCenter = true,
  squareColor,
  testID,
  className,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onChangeInternal: any = (e: React.MouseEventHandler<HTMLLabelElement>) => {
    setIsChecked(!isChecked);
    if (onChange) onChange(e);
  };

  return (
    <>
      {variant === 'filled' ? (
        <CheckboxWrapper
          className={className}
          alignCenter={alignCenter}
          disabled={disabled}>
          <input
            type="checkbox"
            id={id}
            name={name}
            checked={isChecked}
            defaultChecked={defaultChecked}
            data-testid={testID}
            onChange={onChangeInternal}
            disabled={disabled}
          />
          <CheckboxFilledSquare
            onChange={onChangeInternal}
            active={isChecked}
            checkboxSquareHeight={checkboxSquareHeight}
            checkboxSquareWidth={checkboxSquareWidth}
            checkMarkPosRight={checkMarkPosRight}
            checkMarkPosTop={checkMarkPosTop}
            marginBetweenLabel={marginBetweenLabel}
            squareColor={squareColor}
            error={error}
          />
          {label && (
            <CheckboxFilledLabel
              labelColor={labelColor}
              labelFontSize={labelFontSize}
              labelWidth={labelWidth}>
              {HtmlParser(String(label))}
            </CheckboxFilledLabel>
          )}
        </CheckboxWrapper>
      ) : (
        <CheckboxWrapper
          className={className}
          alignCenter={alignCenter}
          disabled={disabled}>
          <input
            type="checkbox"
            id={id}
            name={name}
            checked={isChecked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            data-testid={testID}
            onChange={onChangeInternal}
          />
          <CheckboxOutlinedSquare
            onClick={onChangeInternal}
            active={isChecked}
            checkboxSquareHeight={checkboxSquareHeight}
            checkboxSquareWidth={checkboxSquareWidth}
            checkMarkPosRight={checkMarkPosRight}
            checkMarkPosTop={checkMarkPosTop}
            marginBetweenLabel={marginBetweenLabel}
            squareColor={squareColor}
            error={error}
          />
          {label && (
            <CheckboxOutlinedLabel
              labelColor={labelColor}
              labelFontSize={labelFontSize}
              labelWidth={labelWidth}
              onClick={onChangeInternal}>
              {HtmlParser(String(label))}
            </CheckboxOutlinedLabel>
          )}
        </CheckboxWrapper>
      )}
    </>
  );
};

export default Checkbox;
