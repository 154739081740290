import {theme} from '@styles/theme';
import styled from 'styled-components';
import {StyledInputProps} from './PhoneInput';

export const InputWrapper = styled.div`
  display: flex;
  border: ${({borderColor, focus, theme, error}: StyledInputProps) => {
    switch (true) {
      case borderColor && borderColor.length !== 0 && error && error!.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      case borderColor && borderColor.length !== 0:
        return `1px solid ${borderColor}`;
      case focus:
        return `1px solid ${theme.colors.gray}`;
      case error && error.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      default:
        return `1px solid ${theme.colors.lightGrayV2}`;
    }
  }};
  transition: all 0.5s;
  border-radius: ${theme.rounded};
  background-color: transparent;
  width: 100%;
  position: relative;
  & label {
    background-color: ${({labelBGColor}: StyledInputProps) =>
      labelBGColor ? `${labelBGColor}` : 'white'};
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    transition: color 0.5s, border 0.5s;
    color: ${({labelColor, theme, error}: StyledInputProps) => {
      switch (true) {
        case labelColor && labelColor.length !== 0 && error && error!.length !== 0:
          return `${theme.colors.darkRed} !important`;
        case labelColor && labelColor.length !== 0:
          return `${labelColor}`;
        case error && error.length !== 0:
          return `${theme.colors.darkRed} !important`;
        default:
          return `${theme.colors.gray}`;
      }
    }};
    padding: 0 2px;
    position: absolute;
    top: -7px;
    left: 10px;
  }
  & input {
    width: 100%;
    border: none;
    outline: none;
    padding: 16px 10px 16px 13px;
    margin: 0;
    font-size: 18px;
    background-color: transparent;
    width: 98.5%;
    letter-spacing: 0.5px;
    color: ${({textColor}: StyledInputProps) =>
      textColor ? `${textColor}` : `${theme.colors.darkText}`};
    ::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: ${({placeholderColor}) =>
        placeholderColor ? `${placeholderColor}` : `${theme.colors.gray}`};
    }
    &[readonly],
    &[disabled] {
      opacity: .8;
      cursor: not-allowed;
    }
    
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({textColor}: StyledInputProps) =>
      textColor ? `${textColor}` : 'black'} !important;
  }
`;

export const ErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkRed};
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 16px;
  width: fit-content;
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  z-index: 1;
`;
