import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SubWrapper = styled.div<{isOpen: boolean}>`
  position: relative;
  display: ${({isOpen}) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 520px) {
    margin-bottom: 0;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.span`
  font-family: ${theme.fonts.headline};
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.25px;
  color: ${theme.colors.darkText};

  @media screen and (max-width: 520px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Subtitle = styled.span`
  font-family: ${theme.fonts.subline};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkText};

  @media screen and (max-width: 520px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const DataFieldWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 30px;
  @media screen and (max-width: 520px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  max-width: 250px;
  width: 100%;

  @media screen and (max-width: 520px) {
    max-width: 100%;
    height: 50px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  @media screen and (max-width: 520px) {
    margin-top: 30px;
  }
`;

export const CheckboxLabel = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: block;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
`;

export const GreenText = styled.span`
  color: ${theme.colors.brandGreen};
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 30px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const FirstRowWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 67%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  @media screen and (max-width: 520px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const SecondRowWrapper = styled.div`
  width: 33%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
