import {ChangeEvent, FC, useState} from 'react';
import {DefaultTheme} from 'styled-components';
import {ErrorText, TextareaWrapper} from './Textarea.styles';

interface TextareaProps {
  id: string;
  name?: string;
  defaultValue?: string | number;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  testID?: string;
  label?: string;
  style?: Record<string, any>;
  labelBGColor?: string;
  borderColor?: string;
  labelColor?: string;
  textColor?: string;
  error?: string;
  withoutPadding?: boolean;
  cols?: number;
  rows?: number;
  maxLength?: number;
  resize?: boolean;
  placeholderColor?: string;
  disabled?: boolean;
}

export interface StyledTextareaProps {
  labelBGColor: string;
  labelColor: string;
  borderColor: string;
  textColor: string;
  focus: boolean;
  theme: DefaultTheme;
  error: string | undefined;
  withoutPadding: boolean;
  resize: boolean;
  placeholderColor: string;
}

const Textarea: FC<TextareaProps> = ({
  value,
  defaultValue,
  onChange,
  onBlur = () => false,
  placeholder,
  id,
  name,
  testID,
  label,
  style,
  labelBGColor,
  borderColor,
  labelColor,
  textColor,
  error,
  withoutPadding = false,
  cols = 1,
  rows = 1,
  resize = false,
  maxLength,
  placeholderColor,
  disabled = false,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <TextareaWrapper
      onFocus={() => setIsFocus(!isFocus)}
      focus={isFocus}
      error={error}
      style={style}
      labelBGColor={labelBGColor!}
      borderColor={borderColor!}
      labelColor={labelColor!}
      textColor={textColor!}
      withoutPadding={withoutPadding}
      resize={resize}
      placeholderColor={placeholderColor!}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        data-testid={testID}
        placeholder={placeholder}
        maxLength={maxLength}
        cols={cols}
        rows={rows}
        disabled={disabled}
        onBlur={() => {
          setIsFocus(false);
          onBlur();
        }}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </TextareaWrapper>
  );
};

export default Textarea;
