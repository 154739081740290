import Image from 'next/image';
import React, {ChangeEvent, forwardRef, useState} from 'react';
import {DefaultTheme} from 'styled-components';
import {
  ErrorText,
  IconWrapper,
  InputWrapper,
  InsideInputLabel,
  Label,
} from './Input.styles';

export interface InputProps {
  id: string;
  name?: string;
  title?: string;
  min?: number;
  defaultValue?: string | number;
  max?: number;
  size?: number;
  type?: string;
  step?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: any) => void;
  testID?: string;
  label?: string;
  className?: string;
  style?: Record<string, any>;
  labelBGColor?: string;
  borderColor?: string;
  labelColor?: string;
  textColor?: string;
  error?: string | (() => string);
  withoutPadding?: boolean;
  placeholderColor?: string;
  placeholderFontSize?: number;
  iconSrc?: string;
  onClickIcon?: () => void;
  readonly?: boolean;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  rounded?: boolean;
  insideInputLabel?: string;
  withInsideInputLabel?: boolean;
  turnAutocomplete?: string;
  withoutErrorText?: boolean;
}

export interface StyledInputProps {
  labelBGColor?: string;
  labelColor?: string;
  borderColor?: string;
  textColor?: string;
  focus?: boolean;
  theme?: DefaultTheme;
  error?: string | undefined;
  withoutPadding?: boolean;
  placeholderColor?: string;
  placeholderFontSize?: number;
  rounded?: boolean;
  inputHaveValue?: boolean;
  withInsideInputLabel?: boolean;
  placeholderLength?: number;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'string',
      step,
      title,
      value,
      defaultValue,
      onChange,
      onFocus = () => false,
      onBlur,
      min,
      max,
      size = 15,
      placeholder,
      id,
      name,
      testID,
      label,
      style,
      labelBGColor,
      borderColor,
      labelColor,
      textColor,
      error,
      withoutPadding = false,
      className,
      placeholderColor,
      placeholderFontSize,
      iconSrc,
      onClickIcon,
      readonly = false,
      disabled = false,
      onKeyDown,
      rounded,
      insideInputLabel,
      withInsideInputLabel,
      turnAutocomplete = 'on',
      withoutErrorText = false,
    },
    ref,
  ) => {
    const [isFocus, setIsFocus] = useState(false);

    return (
      <InputWrapper
        onFocus={e => {
          onFocus(e);
          setIsFocus(!isFocus);
        }}
        focus={isFocus}
        error={typeof error === 'function' ? error() : error}
        style={style}
        borderColor={borderColor!}
        labelColor={labelColor!}
        textColor={textColor!}
        className={className}
        withoutPadding={withoutPadding}
        placeholderColor={placeholderColor!}
        placeholderFontSize={placeholderFontSize!}
        withInsideInputLabel={withInsideInputLabel}
        inputHaveValue={String(value).length !== 0}
        placeholderLength={placeholder?.length}
        rounded={rounded}
        title={label}>
        <Label
          htmlFor={id}
          labelBGColor={labelBGColor!}
          labelColor={labelColor!}
          error={typeof error === 'function' ? error() : error}>
          {label}
        </Label>
        {withInsideInputLabel && (
          <InsideInputLabel htmlFor={id} inputHaveValue={String(value).length !== 0}>
            {insideInputLabel}
          </InsideInputLabel>
        )}
        <input
          id={id}
          name={name}
          type={type}
          step={step}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          data-testid={testID}
          min={min}
          max={type === 'date' ? '9999-12-31' : max}
          placeholder={placeholder}
          onBlur={e => {
            setIsFocus(false);
            if (onBlur) {
              onBlur(e);
            }
          }}
          readOnly={readonly}
          disabled={disabled}
          onKeyDown={onKeyDown}
          autoComplete={turnAutocomplete}
        />
        {!withoutErrorText && error && (
          <ErrorText>{typeof error === 'function' ? error() : error}</ErrorText>
        )}
        {!error && iconSrc && (
          <IconWrapper onClick={onClickIcon}>
            <Image
              src={iconSrc}
              width={24}
              height={24}
              object-fit="contain"
              alt="inputIcon"
            />
          </IconWrapper>
        )}
      </InputWrapper>
    );
  },
);

Input.displayName = 'Input';

export default Input;
