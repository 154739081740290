import Input from '@components/atoms/Input';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const CaptchaContainer = styled.div<{type: 'ral' | 'recaptcha'}>`
  position: ${({type}) => (type === 'recaptcha' ? 'absolute' : 'static')};
`;

export const CaptchaWrapper = styled.div<{vertical?: boolean}>`
  display: flex;
  flex-direction: ${({vertical}) => (vertical ? 'column' : 'row')};
  position: relative;
  align-items: center;
  gap: -10px;

  > *:not(:first-child) {
    margin-top: ${({vertical}) => (vertical ? '-5px' : '0')};
    margin-left: ${({vertical}) => (vertical ? '0' : '-5px')};
  }

  input {
    ::placeholder {
      font-weight: 400;
      font-size: 13px;
      color: ${theme.colors.darkGray};
      padding-left: 5px;
    }
  }
`;

export const CaptchaImageWrapper = styled.div`
  min-width: 160px;
  max-width: 160px;
  min-height: 50px;
  max-height: 50px;
  font-size: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 120%;
  position: relative;
`;

export const ReloadCaptchaButton = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 9px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  z-index: 99;
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;
  transition: background-color 0.25s;

  &:hover {
    span {
      filter: invert(100);
    }

    background-color: ${theme.colors.brandGreenV2};
    transition: background-color 0.25s;
  }
`;

export const CaptchaInputWrapper = styled.div`
  text-align: center;
`;

export const StyledInput = styled(Input)<{validationError?: boolean}>`
  border-color: ${({validationError}) =>
    validationError ? `${theme.colors.darkRed}` : `${theme.colors.lightGrayV2}`};
  min-width: 160px;
  max-width: 160px;
  min-height: 50px;
  max-height: 50px;

  label {
    text-transform: none;
    top: -7px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: ${theme.colors.gray};
  }
`;
