import {theme} from '@styles/theme';
import styled, {DefaultTheme} from 'styled-components';
import {StyledInputProps} from './Input';

export const InputWrapper = styled.div<StyledInputProps>`
  display: flex;
  border: ${({borderColor, focus, theme, error}: StyledInputProps) => {
    switch (true) {
      case borderColor && borderColor.length !== 0 && error && error!.length !== 0:
        return `1px solid ${theme?.colors.darkRed}`;
      case borderColor && borderColor.length !== 0:
        return `1px solid ${borderColor}`;
      case focus:
        return `1px solid ${theme?.colors.gray}`;
      case error && error.length !== 0:
        return `1px solid ${theme?.colors.darkRed}`;
      default:
        return `1px solid ${theme?.colors.lightGrayV2}`;
    }
  }};
  border-radius: ${({rounded}) => (rounded ? '4px' : '0')};
  background-color: transparent;
  width: 100%;
  /* height: 100%; */
  position: relative;
  transition: all 0.5s;
  & input {
    border: none;
    outline: none;
    padding: ${({withoutPadding}: StyledInputProps) =>
      withoutPadding ? '' : '16px 10px 16px 12px'};
    margin: 0;
    padding-top: ${({withInsideInputLabel, inputHaveValue}) =>
      withInsideInputLabel && inputHaveValue ? '26px' : '16px'};
    font-weight: 500;
    font-size: 18px;
    background-color: transparent;
    width: 100%;
    letter-spacing: 0.5px;
    transition: padding 0.25s;
    position: relative;
    color: ${({textColor}: StyledInputProps) =>
      textColor ? `${textColor}` : `${theme.colors.darkText}`};

    &[readonly],
    &[disabled] {
      opacity: .8;
      cursor: not-allowed;
    }
  }
  & input::placeholder {
    font-family: ${theme.fonts.subline};
    font-weight: 400;
    font-size: ${({placeholderFontSize}) =>
      placeholderFontSize ? `${placeholderFontSize}px` : '16px'};
    line-height: 21px;
    color: ${({placeholderColor}) =>
      placeholderColor ? `${placeholderColor}` : `${theme.colors.gray}`};
    white-space: pre-line;
    position: absolute;
    ${({withInsideInputLabel}) => (withInsideInputLabel ? 'bottom: 0px' : '')};
    @media (max-width: 550px) {
      top: ${({placeholderLength}) =>
        placeholderLength && placeholderLength > 38 ? '25%' : '35%'};
    }
    @media (max-width: 420px) {
      top: ${({placeholderLength}) =>
        placeholderLength && placeholderLength > 33 ? '25%' : '35%'};
    }
  }
  & input[type='date' i] {
    font-family: ${theme.fonts.subline};
    font-weight: 400;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({textColor}: StyledInputProps) =>
      textColor ? `${textColor}` : 'black'} !important;
  }
`;

export const ErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkRed};
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 16px;
  width: fit-content;
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
  z-index: 1;
  max-width: 70%;
  overflow-wrap: break-word;
`;

export const IconWrapper = styled.div`
  min-width: 24px;
  min-height: 24px;
  align-self: center;
  position: relative;
  z-index: 6;
  right: 16px;
  cursor: pointer;
`;

export const Label = styled.label<StyledInputProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 21px);
  background-color: ${({labelBGColor}) => (labelBGColor ? `${labelBGColor}` : 'white')};
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.1px;
  transition: color 0.5s, border 0.5s;
  color: ${({labelColor, theme, error}) => {
    switch (true) {
      case labelColor && labelColor.length !== 0 && error && error!.length !== 0:
        return `${theme?.colors.darkRed} !important`;
      case labelColor && labelColor.length !== 0:
        return `${labelColor}`;
      case error && error.length !== 0:
        return `${theme?.colors.darkRed} !important`;
      default:
        return `${theme?.colors.gray}`;
    }
  }};
  padding: 0 4px;
  position: absolute;
  top: -7px;
  left: 7px;
`;

export const InsideInputLabel = styled.label<StyledInputProps>`
  position: absolute;
  top: 7px;
  left: 12px;
  right: 10px;
  color: ${theme.colors.brandGreenV2};
  font-family: ${theme.fonts.subline};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  opacity: ${({inputHaveValue}) => (inputHaveValue ? '1' : '0')};
  height: ${({inputHaveValue}) => (inputHaveValue ? 'fit-content' : '0')};
  transition: all 0.25s;
`;
