import {ChangeEvent, FC, useState} from 'react';
import {DefaultTheme} from 'styled-components';
import {FileInputWrapper} from './FileInput.styles';

interface FileInputProps {
  id: string;
  name?: string;
  min?: number;
  defaultValue?: string | number;
  max?: number;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  label?: string;
  style?: Record<string, string>;
  labelBGColor?: string;
  borderColor?: string;
  labelColor?: string;
  textColor?: string;
  error?: string;
  fileName?: string;
  placeholderColor?: string;
  lightIcon?: boolean;
  accept?: string;
}

export interface StyledInputProps {
  labelBGColor: string;
  labelColor: string;
  borderColor: string;
  textColor: string;
  focus: boolean;
  theme: DefaultTheme;
  error: string | undefined;
  fileName: string;
  placeholderColor?: string;
  lightIcon: boolean;
}

const FileInput: FC<FileInputProps> = ({
  value,
  defaultValue,
  onChange,
  onBlur = () => true,
  min,
  max,
  placeholder,
  id,
  name,
  label,
  style,
  labelBGColor,
  borderColor,
  labelColor,
  textColor,
  error,
  fileName = '',
  placeholderColor,
  lightIcon = false,
  accept = '',
}) => {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <FileInputWrapper
      onFocus={() => setIsFocus(true)}
      focus={isFocus}
      error={error}
      style={style}
      labelBGColor={labelBGColor!}
      borderColor={borderColor!}
      labelColor={labelColor!}
      textColor={textColor!}
      fileName={fileName}
      placeholderColor={placeholderColor}
      lightIcon={lightIcon}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        name={name}
        type="file"
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        min={min}
        max={max}
        placeholder={placeholder}
        onBlur={() => {
          setIsFocus(false);
          onBlur();
        }}
        accept={accept}
      />
    </FileInputWrapper>
  );
};

export default FileInput;
