import {setTechCallback} from '@api/clientServices';
import Button from '@components/atoms/Button';
import Checkbox from '@components/atoms/Checkbox';
import DownloadFileByCode from '@components/atoms/DownloadFileByCode';
import FileInput from '@components/atoms/FileInput';
import Input from '@components/atoms/Input';
import PhoneInput from '@components/atoms/PhoneInput';
import Textarea from '@components/atoms/Textarea';
import Captcha from '@components/molecules/Captcha';
import ModalWindow from '@components/molecules/ModalWindow';
import {ENABLE_RAL_CAPTCHA} from '@config/captcha';
import {theme} from '@styles/theme';
import {russianPhoneRegExp} from '@utils/formatters';
import {useFormik} from 'formik';
import Image from 'next/legacy/image';
import {useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as yup from 'yup';
import * as Yup from 'yup';
import {
  ButtonWrapper,
  CheckboxLabel,
  CheckboxWrapper,
  DataFieldWrapper,
  FirstRowWrapper,
  GreenText,
  RowWrapper,
  SecondRowWrapper,
  Subtitle,
  SubTitleWrapper,
  SubWrapper,
  Title,
  TitleContent,
} from './TechSupportModal.styles';

const TechSupportModal = ({
  isModalVisible,
  setIsModalVisible,
}: {
  isModalVisible: boolean;
  setIsModalVisible: {(modalVisible: boolean): void};
}) => {
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string>('');

  const {executeRecaptcha} = useGoogleReCaptcha();

  const schema = yup.object().shape({
    email: yup.string().email('Невалидное значение').required('Обязательное поле'),
    inn: yup.string().required('Обязательное поле'),
    phone: yup
      .string()
      .matches(russianPhoneRegExp, 'Проверьте правильность ввода номера')
      .required('Обязательное поле'),
    message: yup.string().required('Обязательное поле'),
    screen: yup.string().required('Обязательное поле'),
    agreePolitics: Yup.bool().oneOf([true], 'Вы должны дать своё согласие'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      inn: '',
      phone: '',
      message: '',
      agreePolitics: false,
      screen: '',
    },
    onSubmit: async (values, {setErrors, resetForm}) => {
      setLoading(true);
      let token = '';
      if (executeRecaptcha) token = await executeRecaptcha();
      const res = await setTechCallback({...values, ['g-recaptcha-response']: token});

      setLoading(false);
      if (res.success) {
        setIsSucceeded(true);
        formik.resetForm();
        setFileName('');
      } else if (
        res.errors_list?.length > 1 ||
        (res.errors_list?.length === 1 &&
          res.errors_list[0].prop !== 'g-recaptcha-response')
      ) {
        const newErrors: any = {};
        res.errors_list.forEach((item: {prop: string; error: string}) => {
          newErrors[item.prop] = item.error;
        });
        setErrors(newErrors);
      } else {
        setIsFailed(true);
      }
    },
    validationSchema: schema,
  });

  function onCloseForm() {
    setIsModalVisible(false);
    setIsSucceeded(false);
    setIsFailed(false);
  }

  return (
    <ModalWindow isOpen={isModalVisible} height="none">
      {!isSucceeded && !isFailed ? (
        <SubWrapper isOpen={isModalVisible}>
          <SubTitleWrapper>
            <TitleContent>
              <Title>Обращение в службу технической поддержки</Title>
              <Subtitle>
                Служба технической поддержки сайта оказывает помощь по техническим
                вопросам. Ответ службы технической поддержки будет направлен Вам на
                e-mail. По всем другим вопросам Вы можете обратиться к нашему он-лайн
                консультанту.
              </Subtitle>
            </TitleContent>
            <div>
              <Image
                src={'/assets/icons/closeBtn.svg'}
                layout="fixed"
                width={21}
                height={21}
                onClick={() => setIsModalVisible(false)}
                style={{cursor: 'pointer'}}
                alt="Закрыть"
              />
            </div>
          </SubTitleWrapper>
          <RowWrapper>
            <FirstRowWrapper>
              <Input
                id="email"
                label="Почта*"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder={'Введите email'}
                error={
                  formik.touched.email && formik.errors.email ? formik.errors.email : ''
                }
              />
              <Input
                id="inn"
                label="ИНН*"
                value={formik.values.inn}
                onChange={formik.handleChange}
                placeholder={'Введите ИНН'}
                error={formik.touched.inn && formik.errors.inn ? formik.errors.inn : ''}
              />
            </FirstRowWrapper>
            <SecondRowWrapper>
              <PhoneInput
                id="phone"
                label="Телефон*"
                onChange={tel => formik.setFieldValue('phone', tel)}
                value={formik.values.phone}
                error={
                  formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''
                }
              />
            </SecondRowWrapper>
          </RowWrapper>
          <DataFieldWrapper>
            <Textarea
              id="message"
              label="Описание проблемы*"
              placeholder="Опишите проблему: порядок действий, при котором возникает проблема, желаемый результат, сообщения об ошибках и т.д"
              onChange={formik.handleChange}
              value={formik.values.message}
              error={
                formik.touched.message && formik.errors.message
                  ? formik.errors.message
                  : ''
              }
              rows={3}
            />
          </DataFieldWrapper>
          <DataFieldWrapper>
            <FileInput
              id="screen"
              onChange={e => {
                if (e.target.files!.length !== 0) {
                  const reader = new FileReader();
                  reader.onload = e => {
                    const base64String = e.target!.result?.toString().split(',')[1];
                    formik.setFieldValue('screen', base64String);
                  };
                  setFileName(e.target.files![0].name);
                  reader.readAsDataURL(e.currentTarget.files![0]);
                }
                if (e.target.files!.length === 0) {
                  setFileName('');
                  formik.setFieldValue('screen', '');
                }
              }}
              label="Изображение*"
              fileName={fileName}
              placeholderColor={theme.colors.gray}
              accept=".png, .jpg, .jpeg"
              error={
                formik.touched.screen && formik.errors.screen ? formik.errors.screen : ''
              }
            />
            <Captcha
              type={ENABLE_RAL_CAPTCHA ? 'ral' : 'recaptcha'}
              /* onChange={formik.handleChange}
              value={formik.values.captcha}
              error={
                ENABLE_RAL_CAPTCHA
                  ? formik.errors.captcha && touched.captcha
                    ? errors.captcha
                    : ''
                  : errors['g-recaptcha-response'] && touched['g-recaptcha-response']
                  ? errors['g-recaptcha-response']
                  : ''
              }
              validationError={
                ENABLE_RAL_CAPTCHA
                  ? Boolean(errors.captcha && touched.captcha)
                  : Boolean(
                      errors['g-recaptcha-response'] && touched['g-recaptcha-response'],
                    )
              } */
            />
            <ButtonWrapper>
              <Button
                title="Отправить"
                variant="filled"
                style={{width: '100%', padding: '15px 24px'}}
                fontSize={16}
                onClick={() => formik.handleSubmit()}
                disabled={loading}
              />
            </ButtonWrapper>
          </DataFieldWrapper>
          <CheckboxWrapper>
            <Checkbox
              id="agreePolitics"
              checked={formik.values.agreePolitics}
              onChange={formik.handleChange}
              label=""
              variant="filled"
              labelFontSize="12"
              checkboxSquareWidth={24}
              checkboxSquareHeight={24}
              checkMarkPosTop={18}
              checkMarkPosRight={20}
              squareColor={theme.colors.lightGrayV2}
              error={
                formik.touched.agreePolitics && formik.errors.agreePolitics
                  ? formik.errors.agreePolitics
                  : ''
              }
            />
            <CheckboxLabel htmlFor="agreePolitics">
              Нажимая кнопку «Отправить», вы соглашаетесь с{' '}
              <DownloadFileByCode code="personal_information">
                «Политикой в области обработки и защиты персональных данных»
              </DownloadFileByCode>
              , а также даете{' '}
              <DownloadFileByCode code="soglasie_na_obrabotku_personalnykh_dannykh">
                согласие на обработку персональных данных
              </DownloadFileByCode>
              , указанных вами в заявке.
            </CheckboxLabel>
          </CheckboxWrapper>
        </SubWrapper>
      ) : !isFailed ? (
        <SubWrapper isOpen={isModalVisible}>
          <SubTitleWrapper>
            <TitleContent>
              <Title>
                Ваш запрос <GreenText>успешно</GreenText> отправлен
              </Title>
              <Subtitle>
                Мы получили ваше обращение, наша служба поддержки свяжется с вами в
                ближайшее время.
              </Subtitle>
            </TitleContent>
            <div>
              <Image
                src={'/assets/icons/closeBtn.svg'}
                layout="fixed"
                width={21}
                height={21}
                onClick={() => onCloseForm()}
                style={{cursor: 'pointer'}}
                alt="Закрыть"
              />
            </div>
          </SubTitleWrapper>
        </SubWrapper>
      ) : (
        <SubWrapper isOpen={isModalVisible}>
          <SubTitleWrapper>
            <TitleContent>
              <Title>Не удалось обработать ваше обрашение</Title>
              <Subtitle>Не удалось обработать ваше обращение, попробуйте позже.</Subtitle>
            </TitleContent>
            <div>
              <Image
                src={'/assets/icons/closeBtn.svg'}
                layout="fixed"
                width={21}
                height={21}
                onClick={() => onCloseForm()}
                style={{cursor: 'pointer'}}
                alt="Закрыть"
              />
            </div>
          </SubTitleWrapper>
        </SubWrapper>
      )}
    </ModalWindow>
  );
};

export default TechSupportModal;
